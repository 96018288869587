<template>
  <div class="goods-big">
    <div v-for="(item, index) in value" :key="index">
      <div
        class="big-item"
        @click="jumpTo(item)"
        :style="item.images ? '' : 'display: none'"
      >
        <van-image
          width="94vw"
          height="94vw"
          :src="item.images ? item.images[0] : ''"
          style="border-radius: 5px; overflow: hidden"
          fit="cover"
        />
        <div class="name van-multi-ellipsis--l2">
          {{ item.subject }}
        </div>
        <div class="goods-bottom">
          <!-- <div class="shop">洪湖水产专营店</div> -->
          <div class="bottom-right">
            <div class="price">
              <span>￥</span>
              <span style="font-size: 18px">{{ item.salePrice }}</span>
            </div>
            <div class="num">
              <span>已售</span>
              <span>{{ item.sales || item.saleNum || "0" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsBig",
  props: {
    static: {
      default: false,
    },
    value: {
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    jumpTo(item) {
      this.static
        ? null
        : this.$router.push({
            path: "makerGoodsDetail",
            query: { id: item.id },
          });
    },
  },
};
</script>

<style lang="less" scoped>
.goods-big {
  .big-item {
    margin: 1vw;
    padding: 2vw;
    width: 94vw;
    background-color: #fff;
    border-radius: 5px;
    .name {
      margin-bottom: 8px;
      height: 40px;
    }
    .goods-bottom {
      font-size: 12px;
      color: #999;
      display: flex;
      align-items: baseline;
      .shop {
        width: 60%;
      }
      .bottom-right {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 40%;
        .price {
          color: #a80000;
        }
      }
    }
  }
}
</style>