<template>
  <div class="commodity-big-pic">
    <model-title
      v-if="preview"
      title="大图商品"
      :primary="primary"
      :lock="lock"
      :gold="gold"
      :unlockId="7"
      :modulesType="1"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <div v-if="value.length">
      <div
        v-for="(item, index) in value"
        :key="index"
        style="margin-bottom: 10px; position: relative"
      >
        <van-image
          :class="primary ? 'primary' : 'small'"
          :src="item.images ? item.images[0] : src"
          fit="cover"
        ></van-image>
        <div
          style="font-size: 10px; color: #333; margin-top: 5px"
          class="van-ellipsis"
        >
          {{ item.subject }}
        </div>
        <div style="font-size: 10px; color: #333">{{ item.salePrice ? '￥' + item.salePrice : '' }}</div>
        <div class="invalid" v-if="item.status == 1">已失效</div>
      </div>
    </div>

    <div v-else>
      <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
      <div style="font-size: 10px; color: #333; margin-top: 5px">商品名称</div>
      <div style="font-size: 10px; color: #333">￥99.99</div>
    </div>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "commodityBigPic",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    preview: {
      default: true,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      src: require('@/assets/fitment/default.png')
    };
  },
  computed: {
    lock() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[1].lock
        : true;
    },
    gold() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[1].cost
        : 0;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.commodity-big-pic {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  .primary {
    width: 90vw;
    height: 90vw;
  }
  .small {
    width: 44vw;
    height: 44vw;
  }
  .invalid {
    position: absolute;
    left: 50%;
    top: 15vw;
    transform: translateX(-50%);
    width: 60vw;
    height: 60vw;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
</style>