<template>
  <div class="commodity-list">
    <model-title
      v-if="preview"
      title="列表商品"
      :primary="primary"
      :lock="lock"
      :gold="gold"
      :unlockId="6"
      :modulesType="1"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <div v-if="value.length">
      <div class="list-item" v-for="(item, index) in value" :key="index">
        <van-image
          :class="primary ? 'primary' : 'small'"
          :src="item.images ? item.images[0] : src"
          style="flex-shrink: 0"
          fit="cover"
        ></van-image>
        <div class="item-right">
          <div
            style="color: #333; margin-top: 5px"
            class="van-multi-ellipsis--l2"
          >
            {{ item.subject }}
          </div>
          <div style="color: #333">{{ item.salePrice ? '￥' + item.salePrice : '' }}</div>
        </div>
        <div class="invalid" v-if="item.status == 1">已失效</div>
      </div>
    </div>

    <div v-else>
      <div class="list-item" v-for="item in 2" :key="item">
        <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
        <div class="item-right">
          <div style="color: #333; margin-top: 5px">商品名称</div>
          <div style="color: #333">￥99.99</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "commodityList",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    preview: {
      default: true,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      src: require('@/assets/fitment/default.png')
    };
  },
  computed: {
    lock() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[0].lock
        : true;
    },
    gold() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[0].cost
        : 0;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.commodity-list {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  min-width: 44vw;
  .list-item {
    display: flex;
    margin: 10px 0;
    position: relative;
    .primary {
      width: 24vw;
      height: 24vw;
    }
    .small {
      width: 12vw;
      height: 12vw;
    }
    .item-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 10px;
      margin-left: 10px;
    }
    .invalid {
      position: absolute;
      left: 3vw;
      top: 50%;
      transform: translateY(-50%);
      width: 18vw;
      height: 18vw;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
}
</style>