<template>
  <div class="model-pic"></div>
</template>

<script>
export default {
  name: "modelPic",
  props: [],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.model-pic {
  background-color: #edfaff;
}
</style>