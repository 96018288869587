<template>
  <div class="commodity-two">
    <model-title
      v-if="preview"
      title="两列商品"
      :primary="primary"
      :lock="false"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <div style="display: flex; flex-wrap: wrap" v-if="value.length">
      <div class="two-item" v-for="(item, index) in value" :key="index">
        <van-image
          :class="primary ? 'primary' : 'small'"
          :src="item.images ? item.images[0] : src"
          fit="cover"
        ></van-image>
        <div class="item-bottom">
          <div
            style="color: #333; margin-top: 5px; max-width: 44vw"
            class="van-multi-ellipsis--l2"
          >
            {{ item.subject }}
          </div>
          <div style="color: #333">{{ item.salePrice ? '￥' + item.salePrice : '' }}</div>
        </div>
        <div class="invalid" v-if="item.status == 1">已失效</div>
      </div>
    </div>

    <div style="display: flex" v-else>
      <div class="two-item" v-for="item in 2" :key="item">
        <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
        <div class="item-bottom">
          <div style="color: #333; margin-top: 5px">商品名称</div>
          <div style="color: #333">￥99.99</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "commodityTwo",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    preview: {
      default: true,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      src: require('@/assets/fitment/default.png')
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.commodity-two {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  .two-item {
    margin-bottom: 10px;
    position: relative;
    &:nth-of-type(2n-1) {
      margin-right: 2vw;
    }
    .primary {
      width: 44vw;
      height: 44vw;
    }
    .small {
      width: 21vw;
      height: 21vw;
    }
    .item-bottom {
      font-size: 10px;
    }
    .invalid {
      position: absolute;
      left: 50%;
      top: 7vw;
      transform: translateX(-50%);
      width: 30vw;
      height: 30vw;
      background-color: rgba(0,0,0,.6);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
}
</style>