<template>
  <div class="model-title">
    <div style="color: #333; font-weight: 500; line-height: 24px">
      {{ title }}
    </div>

    <div v-if="primary">
      <van-button size="mini" class="shadow-btn" @click="$emit('delete')"
        >删除</van-button
      >
      <van-button size="mini" class="shadow-btn" @click="$emit('up')"
        >上移</van-button
      >
      <van-button size="mini" class="shadow-btn" @click="$emit('edit')"
        >编辑</van-button
      >
      <van-button size="mini" class="shadow-btn" @click="$emit('insert')"
        >插入</van-button
      >
    </div>
    <div v-else>
      <van-button
        v-if="!lock"
        plain
        size="mini"
        color="#FF5200"
        @click="$emit('choose')"
        style="width: 56px"
        >选择</van-button
      >
      <van-button
        v-else
        size="mini"
        color="#FF5200"
        @click="unlock"
        style="width: 56px"
        >解锁</van-button
      >
    </div>
  </div>
</template>

<script>
import { unlock } from "@/api/fitment";
export default {
  name: "modelTitle",
  props: ["title", "primary", "lock", "unlockId", "modulesType", "gold"],
  data() {
    return {};
  },
  methods: {
    unlock() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否支付" + this.gold + "创客金币开通该样式？",
        })
        .then(() => {
          // on confirm
          unlock({
            unlockType: "1", // 写死
            unlockId: this.unlockId,
            modulesType: this.modulesType,
            gold: this.gold,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$toast("解锁成功");
              this.$store.dispatch("getUnlockedConfigs").then(() => {
             //
              });
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.model-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .shadow-btn {
    box-shadow: 1px 1px 5px #e0e0e0;
    width: 56px;
  }
}
</style>