<template>
  <div class="fitment-goods">
    <van-nav-bar
      title="商品"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
      right-text="完成"
      @click-right="complete"
    />

    <div class="preview" v-if="type == 1">
      <commodity-two :preview="false"></commodity-two>
    </div>
    <div class="preview" v-else-if="type == 2">
      <commodity-list :preview="false"></commodity-list>
    </div>
    <div class="preview" v-else>
      <commodity-big-pic :preview="false"></commodity-big-pic>
    </div>

    <goods-two :static="true" :value="list" v-if="type == 1"></goods-two>
    <goods-list :static="true" :value="list" v-else-if="type == 2"></goods-list>
    <goods-big :static="true" :value="list" v-else></goods-big>

    <div style="padding: 10px">
      <van-button
        type="primary"
        block
        color="linear-gradient(to left, #FF7200, #FF3C00)"
        @click="addGoods"
        >添加商品</van-button
      >
    </div>
  </div>
</template>

<script>
import commodityTwo from "@/components/fitment-box/commodity-two";
import commodityList from "@/components/fitment-box/commodity-list";
import commodityBigPic from "@/components/fitment-box/commodity-big-pic";

import goodsBig from "@/components/creator-box/goods-big";
import goodsList from "@/components/creator-box/goods-list";
import goodsTwo from "@/components/creator-box/goods-two";

import { mapState, mapGetters } from "vuex";

import { unlockField } from "@/api/fitment";
export default {
  name: "fitmentGoods",
  components: {
    commodityTwo,
    commodityList,
    commodityBigPic,
    goodsBig,
    goodsList,
    goodsTwo,
  },
  data() {
    return {
      index: null,
      type: null,
      case: null,
      init: null,
      list: [],
    };
  },
  created() {
    this.index = this.$route.query.index;
    this.type = this.$route.query.type;
    this.case = +this.$route.query.case;
    this.init = +this.$route.query.init;

    this.list = JSON.parse(JSON.stringify(this.$store.state.fitment.editList));

    if (this.fitment.chosenValue) {
      this.list = this.fitment.chosenValue;
      this.$store.commit("chooseLink", {
        value: null,
      });
      this.$store.commit("setEditList", this.list);
    }
  },
  computed: {
    ...mapState(["fitment"]),
    ...mapGetters(["showCaseUsable"]),
  },
  methods: {
    // 添加商品
    addGoods() {
      console.log(this.case + this.init - this.list.length > 0);
      if (this.case + this.init - this.list.length > 0) {
        this.$router.push({
          path: "fitmentAddGoods",
          query: {
            multiple: true,
            max: this.case + this.init,
            data: JSON.stringify(this.list),
          },
        });
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message:
              "尊敬的创客商家您可使用的" +
              this.fitment.showCaseNum +
              "个免费橱窗栏已用完，是否花费" +
              this.fitment.showCaseGold +
              "创客金币解锁新的橱窗栏目",
          })
          .then(() => {
            console.log("confirm");
            unlockField({
              addFee: this.fitment.showCaseGold,
            }).then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$toast("解锁成功");
                this.case++;
                this.$store.dispatch("getShowcase").then((res) => {
                  console.log(res);
                });
              }
            });
          })
          .catch(() => {});
      }
    },
    // 右上角完成
    complete() {
      this.$store.commit("setListValue", {
        index: this.index,
        value: this.list,
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-goods {
  background-color: #f6f6f6;
  .preview {
    background-color: #555;
    padding: 2vw 0;
    display: flex;
    justify-content: center;
  }
}
</style>