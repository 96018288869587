<template>
  <div class="goods-list">
    <div v-for="(item, index) in value" :key="index">
      <div
        class="list-item"
        @click="jumpTo(item)"
        :style="item.images ? '' : 'display: none'"
      >
        <van-image
          width="30vw"
          height="30vw"
          :src="item.images ? item.images[0] : ''"
          style="border-radius: 5px; overflow: hidden; flex-shrink: 0"
          fit="cover"
        />
        <div class="list-right">
          <div class="right-top">
            <div class="name van-multi-ellipsis--l2">
              {{ item.subject }}
            </div>
            <!-- <div class="shop">洪湖水产专营店</div> -->
          </div>
          <div class="right-bottom">
            <div class="price">
              <span>￥</span>
              <span style="font-size: 18px">{{ item.salePrice }}</span>
            </div>
            <div class="num">
              <span>已售</span>
              <span>{{ item.sales || item.saleNum || "0" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsList",
  props: {
    static: {
      default: false,
    },
    value: {
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    jumpTo(item) {
      this.static
        ? null
        : this.$router.push({
            path: "makerGoodsDetail",
            query: { id: item.id },
          });
    },
  },
};
</script>

<style lang="less" scoped>
.goods-list {
  .list-item {
    margin: 1vw;
    padding: 2vw;
    width: 94vw;
    background-color: #fff;
    border-radius: 5px;
    display: flex;

    .list-right {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .right-top {
        .name {
          margin-bottom: 8px;
          height: 40px;
        }
      }
      .shop,
      .num {
        font-size: 12px;
        color: #999;
      }
      .right-bottom {
        display: flex;
        align-items: baseline;
        .price {
          color: #a80000;
          width: 45%;
        }
      }
    }
  }
}
</style>